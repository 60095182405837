import React, {Fragment} from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import {Container, Row, Col} from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderSlick from "react-slick";  
import styles from '../scss/components/_SlideSustentabilidad.module.scss';

const SlideProducts = () => {

    const data = useStaticQuery(graphql`
        query backgroundSlideSustentabilidad {
            file(base: {eq: "backgroundSectionSustentabilidad.png"}) {
                childImageSharp {
                    fluid(quality: 80) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }`
    );

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        autoplay: false, 
        fade: true,
        autoplaySpeed: 5000,
        speed: 1100,
    };

    return (
        <Fragment>
            <Img fluid={data.file.childImageSharp.fluid} loading="eager" className={styles.ImgBackgroundSection}/>
            <div className={styles.GradientSection}/>
            <SliderSlick {...settings} className="Arrows">
                <Container className={styles.ContainerInfo}>
                    <Row fluid className={styles.RowContentText}>
                        <Col xs={10}>
                            <h3>¿QUÉ ES LA ENERGÍA EÓLICA?</h3>
                        </Col>
                        <Col xs={10}>
                            <p> La energía eólica es la energía obtenida a partir del viento, es
                                decir, la energía cinética generada por efecto de las turbinas de
                                aire, y que es convertida en electricidad u otras formas útiles
                                para las actividades humanas. El término eólico proviene de latín
                                aeolicus, es decir "perteneciente o relativo a Eolo", dios de los
                                vientos en la mitología griega. En la actualidad, la energía eólica
                                es utilizada principalmente para producir electricidad mediante
                                aerogeneradores conectados a las grandes redes de distribución
                                de energía eléctrica.</p>
                        </Col>
                    </Row>
                </Container>
                <Container className={styles.ContainerInfo}>
                    <Row className={styles.RowContentText}>
                        <Col xs={10}>
                            <h3>¿QUÉ ES Y CÓMO FUNCIONA UN AEROGENERADOR?</h3>
                        </Col>
                        <Col xs={10}>
                            <p> Los aerogeneradores son la evolución natural de los molinos
                                de viento y hoy en día son aparatos de alta tecnología. La
                                mayoría de turbinas genera electricidad desde que el viento
                                logra una velocidad entre 3 y 4 metros por segundo, genera
                                una potencia máxima de 15 metros por segundo y se
                                desconecta para prevenir daños cuando hay tormentas con
                                vientos que soplan a velocidades medias superiores a 25
                                metros por segundo durante un intervalo temporal de 10
                                minutos.</p>
                        </Col>
                    </Row>
                </Container>
                <Container className={styles.ContainerInfo}>
                    <Row className={styles.RowContentText}>
                        <Col xs={10}>
                            <h3>¿DÓNDE ESTÁN LOS PARQUES EÓLICOS DE ARGENTINA?</h3>
                        </Col>
                        <Col xs={10}>
                            <p> Los parques eólicos de Argentina se encuentran en
                                Buenos Aires, Chubut, La Rioja y Santiago del Estero.</p>
                        </Col>
                    </Row>
                </Container>
                <Container className={styles.ContainerInfo}>
                    <Row className={styles.RowContentText}>
                        <Col xs={10}>
                            <h3>¿CUÁNTA ENERGÍA PRODUCE?</h3>
                        </Col>
                        <Col xs={10}>
                            <p> La energía del generador, de 690 voltios, pasa por un
                                transformador para adaptarla al voltaje necesario de la red de
                                distribución, generalmente de entre 20 y 132 kilovoltios.</p>
                        </Col>
                    </Row>
                </Container>
            </SliderSlick>
        </Fragment>
    )

}

export default SlideProducts