import React, {Fragment} from 'react';
import Img from 'gatsby-image';
import {Container, Row, Col} from 'react-bootstrap';
import styles from '../scss/components/_InfoBackgroundSustentabilidad.module.scss';

const InfoBackgroundSustentabilidad = ({image}) => (
    <Fragment>
        <Img fluid={image} loading="eager" className="imgBackground" />
        <div className={styles.gradientSustentabilidad}>
        <Container fluid className={styles.SlideContainer}>
            <Row className={styles.SlideRowText}>
                <Col md={'6'} xs={'10'} className={styles.SlideColText}>
                    <h1>ENERGÍA RENOVABLE </h1>
                    <p>Budweiser está 100% comprometida con el
                    medio ambiente. Especialmente a través de la
                    energía renovable. Por eso inauguramos el
                    parque eólico Budweiser en Achiras, Córdoba.
                    Un emprendimiento sin precedentes que hace
                    que toda nuestra cerveza esté elaborada con
                    energía 100% renovable.</p>
                </Col>
            </Row>
        </Container>
        </div>
    </Fragment>
)

export default InfoBackgroundSustentabilidad